import styled from "styled-components";
import { color } from "../../../utils/styles";

export const SessionContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
`;

export const LogoImg = styled.img`
  height: 191px;
  margin: 85px auto 0 auto;
  display: block;
`;

export const Greeting = styled.h1`
  margin: 80px 0 30px 25px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color?.secondary};
`;
export const FormContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0 35px;
  > div {
    margin-bottom: 22px;
    svg {
      color: ${color?.default};
    }
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const MessageLogin = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: ${color?.warning};
  border-radius: 6px;
  color: ${color?.light};
  /* position: absolute; */
  bottom: -35px;
  height: 35px;
  > div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${color?.light};
    color: ${color?.warning};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 12px;
  }
  > span {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;

export const ButtonLoginZone = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  max-width: 500px;
  padding: 0 35px;
  margin-top: 120px;box-sizing: border-box;
  button {
    max-width: 430px;
    width: 100%;
    margin-bottom: 22px;
    :last-child {
      margin-bottom: 20px;
    }
  }
  @media(max-height: 645px) {
    position: relative;
    margin-top: 50px;
  }
`;
