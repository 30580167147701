import styled from "styled-components";
import { color } from "../../../utils/styles";

export const CategoryContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 500px;
`;

// Category header styles
export const HeaderContainer = styled.div`
  h1,
  p {
    font-family: Montserrat;
  }
  h1 {
    margin: 25px 0 0 0;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${color?.secondary};
  }
  p {
    margin: 10px 0 0 0;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8583c1;
  }
`;

export const LogoImg = styled.img`
  height: 120px;
  margin: 30px auto 0 auto;
  display: block;
`;

// Category Body styles

export const BodyCategoryStyles = styled.div`
  margin: 10px auto 0 auto;
  max-width: 332px;
  max-height: 324px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding-top: 20px;
  h5,
  h6 {
    margin: 0;
    text-align: center;
    font-family: Montserrat;
  }
  h5 {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #606182;
  }
  h6 {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #8583c1;
  }
  .detail {
    width: 132px;
    margin: 0 auto;
    img {
      margin-right: 7px;
      width: 15px;
    }
  }
`;

export const CategoryImage = styled.img`
  display: block;
  margin: 0 77px;
`;

// Category Footer styles
export const FooterCategoryContainer = styled.div`
  margin-top: 40px;
`;
export const ButtonZone = styled.div`
  a {
    margin: 0 auto;
    max-width: 362px;
    height: 45px;
    border-radius: 6px;
    border: solid 2px #c20735;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #c20735;
  }
  button {
    margin: 15px auto 0 auto;
    max-width: 362px;width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-radius: 6px;
    background-color: #c20735;
    border: none;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
  }
`;
export const TermPolitics = styled.div`
  width: 283px;
  margin: 0 auto;
  h6 {
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8583c1;
    span {
      font-weight: normal;
      text-align: left;
      color: #8583c1;
      &:first-child {
        margin-right: 30px;
      }
    }
  }
`;
