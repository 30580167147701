import React from "react";
import {
  CategoryDiamante,
  CategoryAlerta,
  CategoryPlata,
  CategoryBronce,
  Aceptacion,
  FInalizacion,
  Calificacion,
} from "../../../assets/img";
import { BodyCategoryStyles, CategoryImage } from "./styles";
const categoryImg = {
  diamante: CategoryDiamante,
  alerta: CategoryAlerta,
  plata: CategoryPlata,
  bronce: CategoryBronce,
};
const BodyCategory = ({ data }) => {
  return (
    <BodyCategoryStyles>
      {categoryImg[data?.categoria?.toLocaleLowerCase()] ? (
        <CategoryImage
          src={categoryImg[data?.categoria?.toLocaleLowerCase()]}
          alt={"Category " + data?.categoria}
        />
      ) : (
        <h6 style={{ textAlign: "center" }}>
          {data?.categoria || "sin categoría"}
        </h6>
      )}
      <h5>Valoración: </h5>
      <div className="detail">
        <h6>
          <img src={Aceptacion} alt="aceptación category" />
          Aceptación: {data?.aceptacion} %
        </h6>
        <h6>
          <img src={FInalizacion} alt="Finalización category" />
          Finalización: {data?.finalizacion} %
        </h6>
        <h6>
          <img src={Calificacion} alt="Calificación category" />
          Calificación: {data?.calificacion}
        </h6>{" "}
      </div>
    </BodyCategoryStyles>
  );
};

export default BodyCategory;
