import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { SessionForm } from "../../components";

import { LoginServices } from "../../services";
import { LoginContext } from "../../context";

const Login = () => {
  const userLogin = useContext(LoginContext);
  const [errorDetected, setErrorDetected] = useState(false);

  if (userLogin?.logUser?.authenticated) {
    return <Navigate to="/" replace />;
  }

  const NewLogin = (data) => {
    LoginServices.login(data.usuario, data.contrasenia)
      .then((body) => {
        if (body?.status === 200) {
          localStorage.setItem("token", body?.data?.token);
          localStorage.setItem("refresh_token", body?.data?.refresh_token);
          delete body?.data?.token;
          delete body?.data?.refresh_token;

          userLogin?.setLogUser({
            user: body?.data,
            authenticated: true,
          });
          setErrorDetected(false);
        } else {
          setErrorDetected(true);
        }
      })
      .catch((error) => {
        setErrorDetected(true);
        console.log(error, "something when wrong");
      });
  };

  return (
    <div>
      <SessionForm
        NewLogin={NewLogin}
        error={errorDetected}
        setErrorDetected={setErrorDetected}
      />
    </div>
  );
};

export default Login;
