import { wualaHTTP } from "./api";

export const Category = async () => {
  const formData = new FormData();
  const token = localStorage.getItem('token');
  formData.append("token", token);
  return wualaHTTP
    .post(`/api/gestion/informacion_categoria/`, formData, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((resp) => resp)
    .catch((error) => error);
};

const service = {
  Category,
};

export default service;
