import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {
  Login,
  Category,
} from "../containers";
import { LoginContext } from "../context";
import PrivateRoute from "./privateRoutes";

const ScreenContainer = () => {
  const [logUser, setLogUser] = useState(() => {
    return JSON.parse(localStorage.getItem("logUser"));
  });

  useEffect(() => {
    localStorage.setItem("logUser", JSON.stringify(logUser));
  }, [logUser]);

  return (
    <BrowserRouter>
      <LoginContext.Provider value={{ logUser, setLogUser }}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <PrivateRoute>
                <Category />
              </PrivateRoute>
            }
          />
          
          <Route exact path="/login/" element={<Login />} />

          <Route
            exact
            path="/categoria/"
            element={
              <PrivateRoute>
                <Category />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </LoginContext.Provider>
    </BrowserRouter>
  );
};

export default ScreenContainer;
