import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "../../../utils/styles";

export const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(p) => (p?.color ? p?.color : color?.light)};
  height: 70px;
  padding-left: 18px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  label {
    margin-left: 37px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${(p) => (p?.color ? color?.light : color?.secondary)};
  }
`;

export const LeftButton = styled(Link)`
  background: none;
  border: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  svg {
    color: ${(p) => (p?.color ? color?.light : color?.secondary)};
  }
`;
