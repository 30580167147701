import React from "react";
import { Logo } from "../../../assets/img";
import { HeaderContainer, LogoImg } from "./styles";
const HeaderCategory = ({ name }) => {
  return (
    <HeaderContainer>
      <LogoImg src={Logo} alt="Wuala Category" />
      <h1>
        ¡Hola,
        {name ? <br /> : ""}
        {name}!
      </h1>
      <p>Tu categoría es:</p>
    </HeaderContainer>
  );
};
export default HeaderCategory;
