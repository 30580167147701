import axios from "axios";

const base = process.env.REACT_APP_API_ENDPOINT_URL;

export const basicHTTP = axios.create({
  baseURL: base,
});

export const autHTTP = axios.create({
  baseURL: base
});

autHTTP.interceptors.request.use(config => {
  if (config.url !== 'api/gestion/autenticacion_usuario/') {
    const token = localStorage.getItem('token');
    if (token) {
      config.data = { ...config.data, token };
    }
  }
  return config;
});

export const wualaHTTP = axios.create({
  baseURL: base
});

wualaHTTP.interceptors.request.use(config => {
  if (config.url !== 'api/gestion/autenticacion_usuario/') {
    const token =`${localStorage.getItem('token')}`;
    if (token) {
      config.headers.Authorization = `${token}`;
    }
  }
  return config;
});