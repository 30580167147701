import React, { useState } from "react";
import {
  SessionContainer,
  LogoImg,
  Greeting,
  FormContainer,
  ButtonLoginZone,
  MessageLogin,
} from "./styles/sessionForm.styles";
import { CommonButton, InputContainer, CommonInput } from "../";
import { Logo } from "../../assets/img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/fontawesome-free-solid";

const SessionForm = ({ NewLogin, setErrorDetected, error }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [seePassword, setSeePassword] = useState(true);

  return (
    <SessionContainer>
      <LogoImg src={Logo} alt="Wuala Category" />
      <Greeting>¡Hola, Genio!</Greeting>
      <FormContainer>
        <div>
          <CommonInput
            type="text"
            value={email || ""}
            onChange={(e) => setEmail(e?.target?.value)}
          />
        </div>
        <InputContainer>
          <CommonInput
            value={password || ""}
            onChange={(e) => setPassword(e?.target?.value)}
            type={seePassword ? "password" : "text"}
          />
          {seePassword ? (
            <FontAwesomeIcon
              icon={faEye}
              onClick={() => setSeePassword(false)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              onClick={() => setSeePassword(true)}
            />
          )}
        </InputContainer>
        
      </FormContainer>
      <ButtonLoginZone>
      {error ? (
          <MessageLogin onClick={() => setErrorDetected(false)}>
            <div>X</div>
            <span>Usuario y/o contraseña incorrectos</span>
          </MessageLogin>
        ) : (
          ""
        )}
        <CommonButton
          onClick={() => {
             setErrorDetected(false);
            NewLogin({ usuario: email, contrasenia:password });
          }}
        >
          Consultar mi categoría
        </CommonButton>
      </ButtonLoginZone>
    </SessionContainer>
  );
};

export default SessionForm;
