import React from "react";
import { FooterCategoryContainer, TermPolitics, ButtonZone } from "./styles";

const FooterCategory = ({closeSession}) => {
  return (
    <FooterCategoryContainer>
      <ButtonZone>
        <a
          href="https://promo.wuala.com.co/categorias/wuala-categorias/"
          target={"_blank"}
          rel="noreferrer"
        >
          Conocer más sobre categorías
        </a>
        <button onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");
                closeSession("");
              }}>Salir</button>
      </ButtonZone>
      <TermPolitics>
        <h6>
          <span>Términos y condiciones</span>{" "}
          <span>Políticas de privacidad</span>
        </h6>
        <h6>Wuala Derechos reservados</h6>
      </TermPolitics>
    </FooterCategoryContainer>
  );
};

export default FooterCategory;
