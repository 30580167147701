import { autHTTP,  } from "./api";

export const login = async (username, password) => {
  const formData = new FormData();
  formData.append("usuario", username);
  formData.append("contrasenia", password);
  return autHTTP
    .post(`/api/gestion/autenticacion_usuario/`, formData, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((resp) => resp)
    .catch((error) => error);
};

const service = {
  login,
};

export default service;
