import React, { useContext, useEffect, useState } from "react";
import { CategoryContainer } from "./components/styles";
import { HeaderCategory, BodyCategory, FooterCategory } from "./components";
import { LoginContext } from "../../context/loginContext";
import { CategoryService } from "../../services";

const Category = () => {
  const userLogin = useContext(LoginContext);
  const [CategoryData, setCategoryData] = useState("");
  const getCategory = () => {
    CategoryService.Category()
      .then((e) => {
        setCategoryData(e?.data[0]);
      })
      .catch(() => setCategoryData(""));
  };
  useEffect(() => {
    getCategory();
  }, []);
  return (
    <CategoryContainer>
      <HeaderCategory name={CategoryData?.nombre_usuario || ""} />
      <BodyCategory data={CategoryData || ""} />
      <FooterCategory closeSession={userLogin?.setLogUser} />
    </CategoryContainer>
  );
};

export default Category;
