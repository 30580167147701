import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "../context";

const PrivateRoute = ({ children }) => {
  const LoginUser = useContext(LoginContext);

  return LoginUser?.logUser?.authenticated ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
