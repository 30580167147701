import styled from "styled-components";
import { color } from "../../utils/styles";

export const CommonButton = styled.button`
  width: 100%;
  border-color: ${(p) =>
    p?.outLineColor
      ? p?.outLineColor
      : p?.default
      ? color?.default
      : color?.primary};
  border: ${(p) =>
    p?.btnDefault ? "none" : p?.outline ? "1px solid" : "none"};
  border-radius: 6px;
  background: ${(p) =>
    p?.btnDefault || p?.outline
      ? "none"
      : p?.default
      ? color?.default
      : color?.primary};
  font-family: Montserrat;
  font-size: 16px;
  height: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(p) =>
    p?.outLineColor
      ? p?.outLineColor
      : p?.outline || p?.btnDefault
      ? p?.default
        ? color?.default
        : color?.primary
      : "#fff"};
`;

export const InputContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 20px;
    top: 12px;
    font-size: 22px;
    color: ${(p) => (p?.color ? p?.color : color?.default)};
  }
`;

export const CommonInput = styled.input`
  height: 45px;
  width: 100%;
  padding-left: 15px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px ${color?.default};
  color: ${color?.default};
`;

export const CommonSelect = styled.select`
  height: 45px;
  width: 100%;
  padding-left: 15px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px ${color?.default};
  background: none;
  color: ${color?.default};
  -webkit-appearance: none;
  -moz-appearance: none;
  ::-ms-expand {
    display: none;
  }
`;
